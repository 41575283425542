<div class="rowButtons">
    <button (click)="onClick()" [disabled]="disabled" class="ignoreme mat-button mat-js-button mat-button--raised mat-js-ripple-effect mat-button--accent" type="button">
      {{saveButtonValue}}
      @if (loading === NetworkConnectionStatus.IsLoading || loading === true) {
        <mat-spinner single-color [diameter]="20" class="spinner-button" />
      }
    </button>
    @switch (loading) {
      @case (true) {
        <mat-spinner single-color [diameter]="20" class="spinner-button" />
      }
      @case (NetworkConnectionStatus.IsLoading) {
        <mat-spinner single-color [diameter]="20" class="spinner-button" />
      }
      @case (NetworkConnectionStatus.Success) {
        <img class="success" [src]="successIcon">
      }
      @case (NetworkConnectionStatus.Error) {
        <img class="warning" [src]="warningIcon">
      }
    }
</div>
