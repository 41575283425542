import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { environment } from 'environments/environment';
import { SuccessStatus } from '../../../shared/enums';

@Component({
  standalone: true,
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss'],
  imports: [MatProgressSpinner],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SaveButtonComponent implements OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() loading: boolean | SuccessStatus;
  @Input() text: string;

  @Output() save: EventEmitter<null> = new EventEmitter<null>();

  public saveButtonValue: string;

  public NetworkConnectionStatus = SuccessStatus;
  public warningIcon = '../../../assets/text-warning-icon.png';
  public successIcon = '../../../assets/checkmark.png';

  ngOnInit() {
    this.showButton();
  }
  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      const status = changes.loading.currentValue;
      if (!environment.production)
        console.log('{status} is the status of loading for invoices', status);
      if (SuccessStatus[status] === SuccessStatus[this.NetworkConnectionStatus.IsLoading]) {
        this.disableButton();
      } else if (status === SuccessStatus.AccountInActive) {
        this.accountInActive();
      } else if (!status || status === null || (status && status < 0)) {
        this.showButton();
      } else {
        this.disableButton();
      }
    }
  }

  showButton() {
    this.saveButtonValue = this.text;
  }

  disableButton() {
    this.saveButtonValue = '';
    this.disabled = true;
  }

  accountInActive() {
    this.saveButtonValue = this.text;
    this.disabled = false;
  }

  onClick(): void {
    if (!this.disabled) {
      this.save.emit();
    }
  }
}
